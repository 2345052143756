
import { Options, Vue } from "vue-class-component";
import AdvanceFilter from "./components/advance-filter.vue";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { CLAIM } from "@/app/infrastructures/misc/RolePermission";
import { ClaimController } from "@/app/ui/controllers/ClaimController";
import { ClaimData, ClaimDetailData } from "@/domain/entities/Claim";
import PagePopupDetail from "@/app/ui/views/claim/page-popup-detail/index.vue";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  components: {
    AdvanceFilter,
    PagePopupDetail
  },
  computed: {
    isDetailAble() {
      return IS_SENDER_ACCOUNT ? true : checkRolePermission(CLAIM.DETAIL);
    }
  }
})
export default class Claim extends Vue {
  get searchValue() {
    return ClaimController.searchValue;
  }
  onSearch(value: string) {
    if (value.length > 3 || value.length === 0) {
      ClaimController.onSearchValue(value);
    }
  }

  beforeUnmount() {
    ClaimController.setStatus("");
    ClaimController.setCategory("");
    ClaimController.setSearch("");
  }

  clearSearch() {
    ClaimController.onClearSearch();
  }

  created() {
    let defaultQueryObject: any = "";
    if (this.$route.query?.q) {
      const urlParams = new URLSearchParams(atob(String(this.$route.query.q)));
      defaultQueryObject = Object.fromEntries(urlParams.entries());
    }
    ClaimController.initDataClaimList(defaultQueryObject);
  }

  initClaim() {
    ClaimController.initDataClaimList();
  }

  get firstRequest() {
    return ClaimController.firstRequest;
  }

  get isLoading() {
    return ClaimController.isLoading;
  }

  get isError() {
    return ClaimController.isError;
  }

  get errorCause() {
    return ClaimController.errorCause;
  }

  get dataClaims() {
    return ClaimController.claimData.data;
  }

  columns = [
    {
      name: "No. Resi",
      key: "noResi",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: ClaimData) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.noResi}</span>`;
      }
    },
    {
      name: "Shipment ID",
      key: "shipmentId",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: ClaimData) => {
        return `<span class='text-left text-black-lp-300 flex justify-center'>${item.shipmentId}</span>`;
      }
    },
    {
      name: "Produk",
      key: "produk",
      styleHead: "w-1/20 text-center",
      render: (item: ClaimData) => {
        return `
        <div class="text-black-lp-200 flex text-center">
            <div class="rounded px-2 py-0 bg-gray-lp-400">
              <span class="capitalize">${item.productType}</span>
            </div>
          </div>`;
      }
    },
    {
      name: "Kategori",
      key: "category",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: ClaimData) => {
        return `<span class='text-left text-black-lp-300 flex'>${item.categoryName}</span>`;
      }
    },
    {
      name: "Status Klaim",
      key: "status",
      styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
      render: (item: ClaimData) => {
        return `<div class='text-left text-black-lp-300 whitespace-no-wrap text-12px xxl:text-14px rounded-full capitalize px-2 py-0 bg-gray-lp-400 font-medium'>
                  ${item.statusName}
                </div>`;
      },
      toolTip: (item: ClaimData) => {
        return `<div class='flex text-black-lp-300'>Klaim ${item.statusName}</div>`;
      }
    },
    {
      name: "Tanggal Update Status",
      key: "statusDate",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: ClaimData) => {
        return `<span class='text-black-lp-300 flex justify-center'>${formatDate(
          item.statusDate
        )}</span>`;
      }
    }
  ];

  onRequest() {
    ClaimController.loadDataClaimList();
  }

  dataDetailClaim: ClaimDetailData = new ClaimDetailData();
  dialogDetailClaim = false;
  async onClickRow(item: ClaimData) {
    MainAppController.showLoading();
    try {
      this.dataDetailClaim = await ClaimController.GetDetailClaim({
        sttNo: item.noResi
      });
      this.dialogDetailClaim = true;
    } catch (err) {
      MainAppController.showErrorMessage(parsingErrorResponse(err));
      this.dataDetailClaim = new ClaimDetailData();
    } finally {
      MainAppController.closeLoading();
    }
  }

  get pagination() {
    return ClaimController.claimData.pagination;
  }
}
