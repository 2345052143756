
import { Vue, Options } from "vue-class-component";
import { AccountController } from "../../controllers/AccountController";
import Card from "./component/others/card.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import CardComponent from "@/app/ui/components/card/index.vue";
import {
  checkRolePermission,
  IS_SENDER_ACCOUNT
} from "@/app/infrastructures/misc/Utils";
import { SHIPMENT_STT_BOOKING } from "@/app/infrastructures/misc/RolePermission";
import { ReportController } from "../../controllers/ReportController";
import { defineAsyncComponent } from "@vue/runtime-core";
import { DashboardController } from "../../controllers/DashboardController";
import { DashboardDeliveryRequest } from "@/data/payload/api/AccountRequest";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
const DashboardConsole = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/console/index.vue")
);
const DashboardPos = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/pos/index.vue")
);
const DashboardOthers = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/others/index.vue")
);
const DashboardPPOB = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/ppob/index.vue")
);
const DashboardReferal = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/referral/index.vue")
);
const ShortcutBooking = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/shortcut-booking/index.vue")
);
const DashboardClient = defineAsyncComponent(() =>
  import("@/app/ui/views/dashboard/component/client/index.vue")
);

@Options({
  components: {
    Card,
    Title,
    Skeleton,
    CardComponent,
    DashboardConsole,
    DashboardPos,
    DashboardOthers,
    DashboardPPOB,
    DashboardReferal,
    ShortcutBooking,
    DashboardClient
  }
})
export default class Dashboard extends Vue {
  mounted() {
    this.getDashboardData();
  }

  get activeMenuTab() {
    return DashboardController.activeMenuTabs.main;
  }

  getDashboardData() {
    switch (this.accountTypeDetail) {
      case "pos":
        this.getDashboardDelivery();
        break;

      case "console":
        DashboardController.setDashboardConsoleData();
        this.initiateGetListDownloadReport();
        break;

      case "sub-console":
        this.getDashboardDelivery();
        break;

      default:
        break;
    }
  }

  unmounted() {
    clearInterval(this.requestInInterval);
    ReportController.setOpenDownloadRequest(false);
    AccountController.onResetDashboardDeliveryData();
    DashboardController.onResetDashboardConsoleData();
  }

  // widget request download
  requestInInterval: any = null;
  initiateGetListDownloadReport() {
    ReportController.onGetListDownloadReport();
    this.requestInInterval = setInterval(
      () => {
        if (this.findWaitingAndToday()) {
          ReportController.onGetListDownloadReport();
        }
      },
      10000 // interval every 10 seconds
    );
  }
  findWaitingAndToday() {
    const checkWaitingAndToday = this.listDownloadReport.findIndex(
      (data: any) => {
        return (
          data.status === "waiting" &&
          new Date(data.createdAt).getDate() === new Date().getDate()
        );
      }
    );
    return checkWaitingAndToday > -1;
  }
  get listDownloadReport() {
    return ReportController.listDownloadReport.data;
  }

  // dashboard
  getDashboardDelivery() {
    AccountController._getDashboardDeliveryData(
      new DashboardDeliveryRequest({
        cache: true
      })
    );
  }

  get profileData() {
    return AccountController.accountData;
  }

  get role() {
    return AccountController.accountData.account_type_detail.type;
  }

  get dashboardDeliveryData() {
    return AccountController.dashboardDeliveryData;
  }
  get dashboardConsoleData() {
    return DashboardController.dashboardConsoleData;
  }

  get isError() {
    return AccountController.isErrorDashboard;
  }

  get isLoading() {
    return (
      AccountController.isLoadingDashboard || DashboardController.isLoading
    );
  }

  get errorCause() {
    return AccountController.errorCauseDashboard;
  }

  handleError() {
    return AccountController.handleError;
  }

  isTooltip = false;

  onSeeMore() {
    alert("Opss... to be development");
  }

  // account type
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  get columnCount() {
    return 3;
  }
  get isEmpty() {
    return (
      !this.isLoading &&
      !this.isError &&
      !this.dashboardDeliveryData.length &&
      !this.dashboardConsoleData.length &&
      !this.isShortcutEnable &&
      !this.configCorporateDashboard
    );
  }

  get isCreateManualAble() {
    return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
  }
  get isCreateForCorporateAble() {
    return FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled();
  }
  get isAbleToCreateShipmentId() {
    return FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled();
  }
  get isAbleToCreateInstantBooking() {
    return FlagsPermissionBooking.permission_instant_booking_create.isEnabled();
  }

  get isShortcutEnable() {
    if (IS_SENDER_ACCOUNT) {
      return false;
    }
    if (this.accountType === "client" && !this.configCorporateDashboard) {
      return (
        this.isCreateManualAble ||
        this.isCreateForCorporateAble ||
        this.isAbleToCreateShipmentId ||
        this.isAbleToCreateInstantBooking
      );
    }
    return (
      (this.isCreateManualAble ||
        this.isCreateForCorporateAble ||
        this.isAbleToCreateShipmentId ||
        this.isAbleToCreateInstantBooking) &&
      this.accountType === "internal"
    );
  }

  get configCorporateDashboard() {
    return process.env.VUE_APP_CONFIG_CORPORATE_DASHBOARD;
  }
  get isDashboardCorporate() {
    if (IS_SENDER_ACCOUNT) {
      return true;
    }
    return this.accountType === "client" && this.configCorporateDashboard;
  }

  get lastSycnCODDashboard() {
    return DashboardController.lastSyncCODDashboard;
  }
}
