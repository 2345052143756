
import { Options, Vue, prop } from "vue-class-component";
import { ClaimController } from "@/app/ui/controllers/ClaimController";
import {
  CATEGORY_CLAIM,
  STATUS_CLAIM
} from "@/app/ui/views/claim/claim-constants";

class Prop {
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["opened"],
  components: {}
})
export default class AdvanceFilter extends Vue.with(Prop) {
  isChevron = false;
  openFilter = false;

  resetFilter() {
    this.statusValue = "";
    this.categoryValue = "";

    ClaimController.onSelectStatus("");
    ClaimController.onSelectCategory("");
  }

  get sumFilter() {
    return (
      (this.statusValue !== "" ? 1 : 0) + (this.categoryValue !== "" ? 1 : 0)
    );
  }

  // filter status
  statusSelect = false;
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }

  get statusValue() {
    return ClaimController.status;
  }
  set statusValue(newValue: string) {
    ClaimController.onSelectStatus(newValue);
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    this.statusValue = value;
  }
  get statusData() {
    return STATUS_CLAIM;
  }

  // filter category
  categorySelect = false;
  onOpenCategorySelect() {
    this.categorySelect = true;
  }
  onCloseCategorySelect() {
    this.categorySelect = false;
  }

  categoryValue = "";
  onSelectCategory(name: string, value: string) {
    this.onCloseCategorySelect();
    this.categoryValue = value;

    ClaimController.onSelectCategory(value);
  }
  get categoryData() {
    return CATEGORY_CLAIM;
  }
}
